.footer {
    z-index: 1000;
    height: 50px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #282c34;
    color: white;
    text-align: center;
    font-size: 13px;
}

.footer .footerContent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}