
.photo-button {
width: 100px;
height: 100px;
bottom: 50px;
left: 50%;
margin-top: -50px;
margin-left: -50px;
position: absolute;
z-index: 1000;
}
.circle {
position: absolute;
top: 12%;
left: 12%;
bottom: 12%;
right: 12%;
border-radius: 100%;
background-color: #ffffff;
opacity: 0;
}
.ring {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;

border-radius: 100%;
border: 0.5em solid #ffffff;
opacity: 0.8;
}
.photo-button .circle, .photo-button .ring {
transition: all 0.25s;
}
.photo-button:hover .circle {
opacity: 1;
}
.photo-button:active .ring {
opacity: 1;
}
.photo-button:active .circle {
opacity: 0.5;
}